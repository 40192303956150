"use client";

import { useEffect } from "react";
import Error from "next/error";
import { Layout } from "@components/Layout/index";
import { trackEvent } from "@lib/track-event";

export default function Custom404Client({ title, config, subscribed }) {
  useEffect(() => {
    trackEvent("view_page", {
      pageTitle: "404",
      pageCategory: "Error Page",
      pageLocation: window.location.href,
      pagePath: window.location.pathname,
    });
  }, []);

  return (
    <Layout title={title} subscribed={subscribed} config={config} modal={false} pageFound={true}>
      <Error statusCode={404} title="This page was not found" />
    </Layout>
  );
}

export function generateMetadata() {
  return {
    title: "404 - Page Not Found",
  };
}
